/* Register.scss */

/* Full screen background */
.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #8900c8 0%, #00bfa5 100%);

  background-size: cover; /* Ensures the background covers the entire screen */
  background-position: center;
}

/* Card background */
.register-card {
  background: linear-gradient(135deg, #ae46de 0%, #0fefd1 100%);
 /* Path to your card background image */
  background-size: cover;
  background-position: center;
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  width: 400px;
}

/* Text styling remains the same */
.register-title {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
  color: #ffff;
}

.register-subtitle {
  text-align: center;
  color: #6c757d;
  margin-bottom: 1.5rem;
}

.register-image {
  width: 40px;
  height: 40px;
}

.form-group {
  margin-bottom: 5px;
}
.label{
  font-size: medium;
  color: white;
}

.form-select,
.form-control {
  border-radius: 8px;
  border: 1px solid #ced4da;
  height: 35px;
  font-size: 14px;
  background-color: #f8f9fa;
}

.btn-submit {
  background-color: #007bff;
  color: white;
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.btn-submit:hover {
  background-color: #0056b3;
}

.terms-link {
  text-decoration: none;
  color: #da430c;
  font-size: medium;
}

.terms-link:hover {
  text-decoration: underline;
}

.disabled {
  color: #f84b0d;
  cursor: not-allowed;
  font-size: medium;
}

.term-condition-checkbox {
  transform: scale(1.2);
  margin-right: 10px;
}


.form-row {
  display: flex;
  justify-content: space-between;
}

.form-row .form-group {
  flex: 0 0 48%;
}

.login-link {
  font-size: medium;
  color: #007bff;
}

.invalid-feedback {
  font-size: medium;
  color: red;
}

// .loginbtn{
//     width: 100%;
//     height: 40px;
//   }

//   .underline{
//     text-decoration: none;
//   }
//   .btncolor{
//     color: white;
//     text-decoration: none;
//   }
//   .color{
//     background-color: #0d6efd;
//     border: #0d6efd;
//     border-radius: 15px;
//     margin-left: 800px;
//   }
//   .stariccolor{
//     color: red;
//     margin-left: 3px;
//   }
//   .stariccolor.green {
//     color: green; /* Set the color for green */
//   }

//   .stariccolor.red {
//     color: red; /* Set the color for red */
//   }
//   .greenstariccolor {
//     color: green; /* Set the color for green */
//   }
//   .text-size{
//     font-size: 22px;
//   }
//   .pic{
//     width: 100px;
//     height: 100px;
//     margin-left: 40%;
//   }
//   .picd{
//     width: 40px;
//     height: 40px;
//     // margin-left: 100px;
//   }
//   .pictablet{
//     width: 270px;
//     height: 230px;
//   }
//   .text{
//     font-size: x-large;
//     font-family: auto;
//     > b {
//       font-weight: bolder;
//       letter-spacing: 2px;
//       line-height: 0%;
//     }
//   }
//   /* CSS */
//   .buttonHover:hover {
//     background-color: #0d6efd !important;
//     color: white !important; /* Optionally change text color */
//   }
//   .body{
//     margin: 0px;
//     padding: 0px;
//     border: none;
//   }
//   * {
//     margin: 0;
//     padding: 0;
//     box-sizing: border-box;
//   }
//   .custom-phone-input {
//     width: 290px !important;
//     /* Add any additional styles as needed */
//   }

//   // body {
//   //   margin: 20px;
//   // }
//   // form {
//   //   width: 300px;
//   // }

//   // workaround
//   .intl-tel-input {
//     display: table-cell;
//   }
//   .intl-tel-input .selected-flag {
//     z-index: 4;
//   }
//   .intl-tel-input .country-list {
//     z-index: 5;
//   }
//   .input-group .intl-tel-input .form-control {
//     border-top-left-radius: 4px;
//     border-top-right-radius: 0;
//     border-bottom-left-radius: 4px;
//     border-bottom-right-radius: 0;
//   }

//   .invalid-message{
//     margin-top: -20px;
//     color: red;
//     font-size: medium;
//   }
//   .custom-checkbox-label {
//     display: flex;
//     align-items: center;
//   }
//   .term-condition-checkbox {
//     width: 18px; /* Adjust size as needed */
//     height: 18px; /* Adjust size as needed */
//     margin-right: 8px; /* Space between checkbox and text */
//   }
