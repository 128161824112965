
.loginbtn{
  width: 100%;
  height: 40px;
}

.underline{
  text-decoration: none;
}
.color{
  background-color: #0d6efd;
  border: #0d6efd;
  border-radius: 15px;
}
.stariccolor{
  color: red;
  margin-left: 3px;
}
.text-size{
  font-size: 22px;
}
.pic{
  width: 120px;
  // height: 100px;
  // margin-left: 40%;
  margin-left: auto; 
  margin-right: auto;
  max-width: 100%; 
  height: auto; 
}
.picd{
  width: 40px;
  // height: 40px;
  // margin-left: 100px;
}
.pictablet{
  width: 270px;
  // height: 200px;
}
.text{
  font-size: x-large;
  font-family: auto;
  > b {
    font-weight: bolder;
    letter-spacing: 2px;
    line-height: 0%;
  }
}
// .buttonHover:hover {
//   background-color: #0d6efd !important;
//   color: white !important; /* Optionally change text color */
// }
.login-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
}

.card-group {
  height: 100%;
  width: 100%;
}

.card-group .card {
  display: flex;
  flex: 1;
}

.card-group .card .card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


