.card-professional .ant-card-head {
  background-color: #0c313f; //  green background for the header
  // background-color:  rgb(255 52 6);  //  green background for the header
  border-radius: 8px 8px 0 0; // Rounded top corners for the header
  padding: 10px; // Padding for the header
  color: #f5efef; // Dark text color for the header
}
.card-professional .ant-input:focus,
.card-professional .ant-select:focus {
  border-color: #0c313f !important; /* Set focus border color */
  outline: none; /* Remove default outline on focus */
}

/* Set text color of inputs and selects */
.card-professional .ant-input,
.card-professional .ant-select {
  color: black; /* Default text color for inputs */
}

/* Change the text color on focus */
.card-professional .ant-input:focus,
.card-professional .ant-select:focus {
  color: #0c313f; /* Text color when the input is focused */
}

/* Set the cursor for input fields when hovered */
.card-professional .ant-input:hover,
.card-professional .ant-select:hover {
  cursor: pointer; /* Make the cursor pointer when hovering */
}

/* Text cursor style on focus */
.card-professional .ant-input:focus,
.card-professional .ant-select:focus {
  cursor: text; /* Text cursor when focused on input fields */
}
/* Custom button styles */
.card-professional .ant-btn-primary {
  background-color: #226c87c7; /* Green background */
  border-color: #226c87c7; /* Green border */
  color: white; /* White text */
}

/* Override the hover effect with a darker green */
.card-professional .ant-btn-primary:hover,
.card-professional .ant-btn-primary:focus {
  background-color: #0c313f !important;
  border-color: #0c313f !important;
  color: white !important;
}

/* Change the button color when it is disabled */
.card-professional .ant-btn-primary[disabled] {
  background-color: lightgray; /* Disabled state background color */
  border-color: lightgray; /* Disabled state border color */
  color: #0c313f; /* Disabled state text color */
}

// ----------- Circular Boxes ------------------

/* General Layout for the Row */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

/* Card Styling Base (Common Styles for All Cards) */
.circular-card {
  border-radius: 50%; /* Circular Shape */
  padding: 20px;
  text-align: center;
  width: 200px;
  height: 200px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: transform 0.3s ease;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@keyframes blinkEffect {
  0% {
    background-color: rgba(255, 0, 0, 0.7);
  }
  50% {
    background-color: rgba(255, 0, 0, 0.4);
  }
  100% {
    background-color: rgba(255, 0, 0, 0.7);
  }
}

@keyframes blinkText {
  0% {
    color: rgb(0, 221, 255);
  }
  50% {
    color: white;
  }
  100% {
    color: rgb(0, 221, 255);
  }
}

.blink {
  animation: blinkEffect 1s infinite alternate;
}

.blink h3 {
  animation: blinkText 1s infinite alternate;
}

.blink h3 {
  animation: blinkText 1s infinite alternate;
}

/* Card Gradient Backgrounds */
.newcard-main-1 {
  background: #4caf50;
  // background: linear-gradient(to top, #ff4b5c, #ff8c42);
} /* Pink to Orange */
.newcard-main-2 {
  background: #1976d2;
  // background: linear-gradient(to top, #6a11cb, #2575fc);
} /* Purple to Blue */
.newcard-main-3 {
  background: linear-gradient(to top, #ff9a8b, #ff6a00);
} /* Coral to Bright Orange */
.newcard-main-4 {
  background: linear-gradient(to top, #2d92d1, #56ccf2);
} /* Blue to Light Sky Blue */
.newcard-main-5 {
  background: linear-gradient(to top, #fc4a1a, #f7b733);
} /* Orange to Yellow */
.newcard-main-6 {
  background: linear-gradient(to top, #6a9a8e, #0f3443);
}
.newcard-main-7 {
  background: linear-gradient(to top, #669999, #0f3443);
}
.newcard-main-8 {
  background: linear-gradient(to top, #009dae, #0f3443);
}
.newcard-main-9 {
  background: linear-gradient(to top, #4d5aa6, #0f3443);
}
.newcard-main-10 {
  background: linear-gradient(to top, #005a80, #0f3443);
}
.newcard-main-11 {
  background: linear-gradient(to top, #647b72, #0f3443);
}

/* Hover Effect for All Cards */
.circular-card:hover {
  transform: translateY(-5px);
}

h3 {
  font-size: large;
  color: white;
}
h4 {
  font-size: medium;
  color: white;
}

.section ul {
  font-size: medium;
  line-height: 1.8;
}

.section p {
  font-size: medium;
  text-align: justify;
}

/* Responsive Styles */
@media (max-width: 786px) {
  .row {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px;
  }

  .circular-card {
    margin: 10px auto;
    border-radius: 50%;
    width: 150px; /* Optional: Smaller size on mobile */
    height: 150px; /* Adjust size for mobile */
  }
}

// --------- spinner style-------------

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  margin: 0 auto;
  width: 50px;
  height: 50px;
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

.spinner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner-text {
  margin-top: 10px;
  font-size: 20px;
  color: #3498db;
  font-weight: bold;
}

.unread-row {
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.1); /* Optional: to highlight the row */
}

.pending-row {
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.1); /* Darker background for pending rows */
}

.expired-row {
  color: red;
}

.badge-button-container {
  position: relative;
  display: inline-block;
}

.badge-overlay {
  position: absolute;
  top: -8px; /* Adjust to place the badge properly */
  right: -8px; /* Adjust for alignment */
  background: red; /* Badge color */
  color: white;
  border-radius: 50%; /* Circular badge */
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  z-index: 1;
}
