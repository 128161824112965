.multilogin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  position: relative;
  overflow: hidden;
}

.header-image {
  position: absolute;
  top: 0;
  left: 0;
}

.tile-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  max-width: 800px;
  margin-top: 50px;
  position: relative;
  z-index: 1;
}

.tile {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 50%;
  text-align: center;
  width: 200px;
  height: 200px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-weight: bold;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
}

.tile h3 {
  margin: 0;
  z-index: 1;
}

.logout-button {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 2; /* Ensure the logout button is above all other content */
}

.tile:hover {
  transform: translateY(-5px);
}

@media (max-width: 786px) {

  .tile-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px;
  }

  .tile {
    width: 200px; /* Adjust size for smaller screens */
    height: 200px; /* Adjust size for smaller screens */
    max-width: 100%;
    margin: 10px auto;
    border-radius: 50%;
  }
}


// /* multilogin.scss */
// .multilogin-container {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     padding: 20px;
//   }

//   .header-image {
//     width: 100%;
//     text-align: center;
//     margin-bottom: 20px;
//   }

//   .header-image img {
//     max-width: 100%;
//     height: auto;
//   }

//   .tile-container {
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: center;
//     gap: 20px;
//   }

//   .tile {
//     background-color: #f8f9fa;
//     border: 1px solid #dee2e6;
//     border-radius: 8px;
//     text-align: center;
//     padding: 20px;
//     width: 300px; /* Increased width */
//   }

//   .tile-image {
//     width: 250px; /* Increased size */
//     height: 150px; /* Increased size */
//     object-fit: cover;
//     border-radius: 8px; /* Square shape with slight rounding */
//     margin-bottom: 10px;
//   }

//   .tile-bam-image {
//     width: 260px; /* Increased size */
//     height: 150px; /* Increased size */
//     object-fit: cover;
//     border-radius: 8px; /* Square shape with slight rounding */
//     margin-bottom: 10px;
//   }

//   .tile h3 {
//     margin: 10px 0;
//   }

//   .tile button {
//     margin-top: 10px;
//   }

//   .logout-button {
//     position: absolute;
//     top: 30px;
//     right: 30px;
//   }
